import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { StockManagementFilterBarState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import { Pagination, StockManagementState } from '../../@types/reduxStates';
import { StockManagementReponse } from '../../@types/responsesAPI';
import axios from '../../utils/axios';

const initialState: StockManagementState = {
  filters: {
    timeInterval: 1,
    groupBy: 1,
    stock: [],
    warehouses: [],
    abc: [],
    profitBy: 0,
    categories: [],
    searchName: '',
    columns: [],
    customers: [],
    groupByCategory: 0,
    suppliers: []
  },
  lang: 'en-GB',
  response: {
    rows: [],
    total: {
      productPeriodAvgDeliveryTime: 0,
      productPeriodAvgPurchasePrice: 0,
      productPeriodCalcMaxStock: 0,
      productPeriodCumulativeTurnoverShare: 0,
      productPeriodDailyConsumption: 0,
      productPeriodHealtyPercent: 0,
      productPeriodHealtyRevenue: 0,
      productPeriodHealtyStock: 0,
      productPeriodMaxDeliveryTime: 0,
      productPeriodNoPurchasePercent: 0,
      productPeriodNoPurchaseRevenue: 0,
      productPeriodNoPurchaseStock: 0,
      productPeriodObsoletePercent: 0,
      productPeriodObsoleteRevenue: 0,
      productPeriodObsoleteStock: 0,
      productPeriodOverPercent: 0,
      productPeriodOverRevenue: 0,
      productPeriodOverstock: 0,
      productPeriodReorderVolume: 0,
      productPeriodTurnoverShare: 0,
      productPeriodUnderPercent: 0,
      productPeriodUnderRevenue: 0,
      productPeriodUnderStock: 0,
      productPeriodUsage: 0,
      productPeriodWeeklyConsumption: 0,
      stockLive: 0,
      stockLiveRevenue: 0,
      stockPeriodAvgStock: 0,
      stockPeriodDays: 0,
      stockPeriodEnd: 0,
      stockPeriodFinalValue: 0,
      stockPeriodMinimum: 0,
      stockPeriodReported: 0,
      stockPeriodRevenue: 0,
      stockPeriodTurnoverRate: 0,
      stockReviewTotal: 0,
      productReOrderTime: 0,
      _id: null
    },
    totalCount: { count: 0 }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'stockManagement',
  initialState,
  reducers: {
    setFilters: (
      state: any,
      action: PayloadAction<StockManagementFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getProfitList: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getProfitListSuccess: (
      state: any,
      action: PayloadAction<StockManagementReponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getProfitListError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function updateStockManagementFilterBarState(
  filterBar: StockManagementFilterBarState
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filterBar));
  };
}

export function fetchStockManagement(
  filters: StockManagementFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    dispatch(getProfitList());
    try {
      const params = {
        skip,
        limit,
        by,
        order,
        groupBy: filters.groupBy,
        timeperiod: filters.timeInterval,
        search: filters.searchName.length > 0 ? filters.searchName : undefined,
        warehouses:
          filters.warehouses.length > 0
            ? filters.warehouses.map((c) => c.warehouseId).join(',')
            : undefined,

        abc:
          filters.abc.length > 0
            ? filters.abc.map((c) => c.abcName).join(',')
            : undefined,

        category:
          filters.categories.length > 0
            ? filters.categories.map((c) => c.categoryExtId).join(',')
            : undefined,
        suppliers:
          filters.suppliers.length > 0
            ? filters.suppliers.map((s) => s.supplierId).join(',')
            : undefined,
        /* countries:
          filters.countries.length > 0
            ? filters.countries.map((c) => c.countryId).join(',')
            : undefined, */
        stock:
          filters.stock.length > 0
            ? filters.stock.map((s) => s.id).join(',')
            : undefined
      };
      const response = await axios.get('/api/v1/stockManagement', {
        params,
        signal: abortController.signal
      });
      const { data } = response;
      dispatch(getProfitListSuccess(data));
    } catch (error) {
      dispatch(getProfitListError(error as APIError));
      dispatch(getProfitListSuccess(initialState.response));
    }
  };
}

function ABC(pos: number) {
  switch (pos) {
    case 1: {
      return 'A';
    }
    case 2: {
      return 'B';
    }
    case 3: {
      return 'C';
    }
    default: {
      return undefined;
    }
  }
}

export const {
  setFilters,
  getProfitList,
  getProfitListError,
  getProfitListSuccess
} = slice.actions;

export default slice.reducer;
