import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { ProfitListFilterBarState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import { Pagination, ProfitListState } from '../../@types/reduxStates';
import { ProfitListReponse } from '../../@types/responsesAPI';
import axios from '../../utils/axios';

const initialState: ProfitListState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    timeInterval: 0,
    groupBy: 0,
    stock: 0,
    shop: {
      shopId: 0,
      shopName: 'All shops'
    },
    profitBy: 0,
    countries: [],
    sources: [],
    status: [],
    categories: [],
    searchName: '',
    columns: [],
    customers: [],
    groupByCategory: 0,
    suppliers: [],
    manufacturers: []
    /* filtersConfigSelected: '0',
    by: 'revenueNetto',
    order: 'desc',
    page: 0,
    rowsPerPage: 10 */
  },
  lang: 'en-GB',
  response: {
    rows: [],
    total: {
      unitsSold: 0,
      revenueBrutto: 0,
      revenueNetto: 0,
      revenueShare: 0,
      discount: 0,
      cancellations: 0,
      netAfterCancellations: 0,
      grossAfterCancellations: 0,
      returns: 0,
      unitsReturn: 0,
      returnsRate: 0,
      shippingCosts: 0,
      operatingCosts: 0,
      transportCosts: 0,
      customs: 0,
      creditPayout: 0,
      netAfterCreditPayout: 0,
      bruttoAfterCreditPayout: 0,
      productCosts: 0,
      db1: 0,
      db1Rate: 0,
      storageCosts: 0,
      paymentFees: 0,
      db2: 0,
      db2Rate: 0,
      advertisingCosts: 0,
      marketplaceFees: 0,
      db3: 0,
      db3Rate: 0,
      currentStock: 0
    },
    totalCount: { count: 0 }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'profitList',
  initialState,
  reducers: {
    setFilters: (
      state: any,
      action: PayloadAction<ProfitListFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getProfitList: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getProfitListSuccess: (
      state: any,
      action: PayloadAction<ProfitListReponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
      state.error = undefined;
    },
    getProfitListError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function updateProfitListFilterBarState(
  filterBar: ProfitListFilterBarState
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filterBar));
  };
}

export const fetchProfitListData = async (
  filters: ProfitListFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  lang: string,
  abortController: AbortController
) => {
  const params = {
    lang,
    skip,
    limit,
    by,
    order,
    from: filters.timePeriod.startFilter,
    till: filters.timePeriod.endFilter,
    timeInterval: filters.timeInterval > 0 ? filters.timeInterval : undefined,
    groupBy: filters.groupBy,
    groupByCategory: filters.groupByCategory,
    stock: filters.stock,
    profitBy: filters.profitBy,
    countries:
      filters.countries.length > 0
        ? filters.countries.map((c) => c.countryId).join(',')
        : undefined,
    customers:
      filters.customers.length > 0
        ? filters.customers.map((c) => c.customerId).join(',')
        : undefined,
    sources:
      filters.sources.length > 0
        ? filters.sources.map((c) => c.sourceId).join(',')
        : undefined,
    status:
      filters.status.length > 0
        ? filters.status.map((st) => st.orderStatusId).join(',')
        : undefined,
    category:
      filters.categories.length > 0
        ? filters.categories.map((c) => c.categoryExtId).join(',')
        : undefined,
    manufacturers:
      filters.manufacturers.length > 0
        ? filters.manufacturers.map((c) => c.manufactureId).join(',')
        : undefined,
    searchName: filters.searchName.length > 0 ? filters.searchName : undefined,
    shop: filters.shop.shopId > 0 ? filters.shop.shopId : undefined,
    suppliers:
      filters.suppliers.length > 0
        ? filters.suppliers.map((c) => c.supplierId).join(',')
        : undefined
  };

  const response = await axios.get('/api/v2/profit/list', {
    signal: abortController.signal,
    params
  });

  return response.data;
};

export function fetchProfitList(
  filters: ProfitListFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  lang: string,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    dispatch(getProfitList());
    try {
      const data = await fetchProfitListData(
        filters,
        { skip, limit },
        { by, order },
        lang,
        abortController
      );
      dispatch(getProfitListSuccess(data));
    } catch (error) {
      dispatch(getProfitListError(error as APIError));
    }
  };
}

export const {
  setFilters,
  getProfitList,
  getProfitListError,
  getProfitListSuccess
} = slice.actions;

export default slice.reducer;
