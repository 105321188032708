import {
  Menu,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import ExportCSV, { CSVExportData } from './ExportCSV';

type Props = {
  options: Array<{ id: number; label: string; icon?: string }>;
  // onMenuAction: (option: string) => void;
  csvData: CSVExportData;
  fileNameSection?: string;
};
const ITEM_HEIGHT = 48;

function MenuMore({
  options,
  // onMenuAction,
  csvData,
  fileNameSection = ''
}: Props) {
  const { t: translate } = useTranslation(['component']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setAnchorEl(null);
  };

  const fileName = `${translate(fileNameSection)}`;

  const dateRange =
    csvData.date.startFilter.length > 0
      ? `_${csvData.date.startFilter}_${csvData.date.endFilter}`
      : '';

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content'
          }
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            selected={option.id === 0}
            onClick={(e) => {
              handleClose(e);
            }}
          >
            {option.icon && (
              <ListItemIcon>
                <Icon width={24} icon={option.icon} />
              </ListItemIcon>
            )}

            {option.id === 0 && csvData && csvData.headers.length > 0 ? (
              <ExportCSV
                label={option.label}
                fileName={`${fileName}${dateRange}`}
                data={csvData}
              />
            ) : (
              <ListItemText>{translate('component:stock_nodata')}</ListItemText>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default MenuMore;
